<template>
    <section>
        
        <!-- ----------- se ainda nao carregou a api ----------- -->
        <div class="loading" v-if="!jaTemDados">
            <div v-if="this.$store.state.isDelivery">
                <!-- <img src="../assets/loading.gif" alt=""/> -->
                <img src="../assets/delivery-logo-branca.png" alt="" style="scale: 2" class="a-branco" />
                <img src="../assets/delivery-logo-branca.png" alt="" style="scale: 2" class="a-branco-vetor" />
                <!-- <img src="../assets/a_branco_vetor.svg" alt="" class="a-branco-vetor"/> -->
                <p style="color:white">Buscando dados do cardápio</p>
            </div>
            <div v-if="!this.$store.state.isDelivery">
                <!-- <img src="../assets/loading.gif" alt=""/> -->
                <img src="../assets/a_branco.png" alt="" class="a-branco" />
                <img src="../assets/a_branco.png" alt="" class="a-branco-vetor" />
                <!-- <img src="../assets/a_branco_vetor.svg" alt="" class="a-branco-vetor"/> -->
                <p style="color:white">Buscando dados do cardápio</p>
            </div>
        </div> 

        <!-- ----------- ja carregou a api ----------- -->
        <div
            class="back"
            v-if="jaTemDados && !casaFechada"
            :key="this.$store.state.empresa"
            :style="{ backgroundImage: 'url(' + imageURL + ')' }"
        >
            <div class="gradiente">
                <!-- nome da empresa  -->
                <h1 class="titulo">{{ empresa }}</h1>
                <p  v-if="this.$store.state.isDelivery" class="home-endereco">{{ this.$store.state.endereco.rua }}, {{ this.$store.state.endereco.numero }}</p>
                <p  v-if="this.$store.state.isDelivery" class="home-endereco" style="font-size: 0.7rem;">{{ this.$store.state.endereco.bairro }} - {{ this.$store.state.endereco.cidade }}, {{ this.$store.state.endereco.uf }}</p>
                <!-- logo da empresa  -->
                <img
                    :src="logoURL"
                    class="logo-empresa"
                />

                <!-- botao abrir cardapio  -->
                <ButtonAbrirCardapio class="btnAbrirCardapio" />

                <!-- site dalcatech  -->
                <div class="bottom">
                    <p>Desenvolvido por</p>
                    <a href="https://www.dalcatech.com.br">www.dalcatech.com.br</a>
                </div>
            </div>

            <router-view />
        </div>


        <div v-if="casaFechada">
            <casa-fechada-view :descricaoAberturaCasa="descricaoAberturaCasa"/>
        </div>
    </section>
</template>

<script>
import { setarCores } from '@/utils/SetarCores';
import ButtonAbrirCardapio from "../components/Home/ButtonAbrirCardapio.vue";
import ApiDelivery from '@/api/ApisDelivery';
import CasaFechadaView from '@/components/Delivery/CasaFechadaView.vue';
import { setarUsarRotaHardRock } from '@/api/rotas';

/**
 * Na primeira vez que os dados vem da api, eles sao setados aqui mas a pagina inicial HomeView.vue ainda nao carrega
 * por isso, na HomeView.vue usamos a resposta direta do axios ao inves de dados do store
 *
 * Aqui vamos chamar o metodo getApi() assim que a pasgina for carregada. Vamos puxar todos os dados e setar tudo na store.
 */
export default {
    name: "HomeView",
    components: {
        ButtonAbrirCardapio,
        CasaFechadaView,
    },
    data() {
        return {
            jaTemDados: false,
            imageURL: "",
            logoURL: "",
            empresa: "",
            loading: false,
            casaFechada: false,
            descricaoAberturaCasa: null
        };
    },
    created() {
        this.getApi();
    },

    watch: {
        empresa(novo, velho) {
            if (novo != velho) {
                this.jaTemDados = true;
            }
        },
        logoURL(novo, velho) {
            if (novo != velho) {
                this.jaTemDados = true;
            }
        },
    },
    methods: {
        /**
         * vai fazer o primeiro get na tabela itensVenda da empresa
         * esse metodo dispara o metodo fecthUsers() na store. Este metodo vai incluir o json na store mas tambem
         * vai trazer o json de resposta para ser usado nesta pagina.
         *
         * Aqui nesta classe, EXCEPCIONALMENTE, nao da tempo de carregar todos os itens, setar na store, e trazer da store
         * entao usamos a resposta do AXIOS diretamente para renderizar essa pagina
         * essa resposa traz, primeiramente, o nome da empresa e a imagem de fundo.
         * Para todos os outros dados, o app usara a store.
         */
        async getApi() {
            console.log("%c aqui vai", "background: blue")
            console.log(window.document.URL)
            console.log(window.document.baseURI)

            /**
             * usar hard rock
             *   cnpj = 41881480000124 - porto alegre
             */

             if (window.document.URL.includes("41881480000124")) {


            //  if (window.document.URL.includes("00906885242592")) {
                setarUsarRotaHardRock()
             }

            this.$store.dispatch("buscarTudo").then( async (res) => {

                this.empresa = res.nomeFantasia;
                this.logoURL = res.personalizacao.logo;
                this.imageURL = res.backgroundImage;

                let cor1 = res.personalizacao.cor1;
                let cor2 = res.personalizacao.cor2;
                let textoEscuro = res.corTextoEscura == "1" || res.corTextoEscura == 1 ? true : false;

                console.log(res.personalizacao);

                setarCores(cor1, cor2, textoEscuro);
                this.jaTemDados = true;
                    
                // 
                // agora vamos buscar se a casa esta fechada, pq se estiver, nao vamos 
                // habilitar o botao CARRINHO
                // 
                await this.deliveryCasaAberta()
            })
            .catch((err) => {
                // alert(err)
                console.log(err)
                this.$router.push({ name: 'ErroVersao', params: { err: err }})
            })

            this.$store.dispatch('setAbaSelecionada', "cardapio")

        },


        
        /**
         * Se estivermos no delivery, vamos checar se a casa esta aberta neste momento,
         * e assim avisamos o usuario caso a casa esteja fechada antes que ele
         * faca o pedido
         */
        async deliveryCasaAberta() {
            if (this.$store.state.isDelivery) {
                let deliveryconfig = await ApiDelivery.verificarCasaEstaAbertaAgora(this.$store.state.cnpj, this.$store.state.numeroEmpresaFK);
                console.log("verificando casa aberta")
                console.log(deliveryconfig)
                    this.$store.dispatch('setCasaAberta', deliveryconfig.aberto)
                    // this.casaFechada = true
                    this.descricaoAberturaCasa = deliveryconfig.descricao
                
            }
             
        },



    },
};
</script>

<style scoped>
.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding-block: 150px;
    background: linear-gradient(to bottom,var(--primaryDark_azul) 0%,rgba(0, 0, 0, 0.7));
    
}
.loading > p {
    color: white;
    margin-top: 30px;
}

.home-endereco {
    color: white;
    margin: 5px
}
.a-branco-vetor {
    position: absolute;
    left: 0;
    top: 50%;
    width: 600px;
    object-fit: cover;
    max-width: 100%;
    opacity: 0.05;
    z-index: 55;
    /* background: red; */
    
}

.a-branco {
    animation: a-branco-anim 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 45px;
}
@keyframes a-branco-anim {
    /* 0% {opacity: 0.0; transform: rotateX(0deg);}
    50% {opacity: 1.0; transform: rotateX(180deg);}
    1000; transform: rotateX(0deg);} */
    0% {
        transform: rotateY(0deg) translateY(20%);
    }
    25% {
        transform: rotateY(360deg) translateY(-30%);
    }
    50% {
        transform: rotateY(360deg) translateY(-20%);
    }
    75% {
        transform: rotateY(360deg) translateY(-30%);
    }
    100% {
        transform: rotateY(0deg) translateY(20%);
    }
}

.bottom {
    position: absolute;
    bottom: 7px;
    margin-bottom: 30px;
    margin-inline: auto;
    text-align: center;
    text-decoration: none;
    color: rgb(150, 150, 150);
    left: 0;
    right: 0;
}
.bottom > a {
    text-align: center;
    /* text-decoration: none; */
    color: white;
}

.back {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: backAnim 1s forwards;
}

@keyframes backAnim {
    to {
        opacity: 1;
    }
}


/* desktops */
@media only screen and (min-width: 720px) {
    .logo-empresa {
        height: 40%;
    }  
}
/* mobiles */
@media only screen and (max-width: 720px) {
    .logo-empresa {
        margin-top: 25px;
        width: 40%;
    }
}

.gradiente {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        rgba(00, 00, 00, 1.0),
        rgba(00, 00, 00, 0.8),
        rgba(00, 00, 00, 0.2),
        rgba(00, 00, 00, 0.6),
        rgba(00, 00, 00, 0.9)
    );
}

.titulo {
    /* margin-top: 1%; */
    color: white;
    animation: titleAnim 0.5s forwards;
}

/* desktops */
@media only screen and (min-width: 720px) {
    @keyframes titleAnim {
    to {
        margin-top: 2%;
    }
}
}
/* mobiles */
@media only screen and (max-width: 720px) {
    @keyframes titleAnim {
    to {
        margin-top: 10%;
    }
}
}

.btnAbrirCardapio {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 40%;
    animation: btnAbrirCardapioAnim 1s forwards;
    opacity: 0;
    animation-delay: 0.8s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes btnAbrirCardapioAnim {
    to {
        opacity: 1;
        /* margin-bottom: 50%; */
    }
}
</style>


user: {
    ...
    subscription: {
        type: "trial_active",
        planName: "free trial",
        planPrice: "0.00",
        planCurrency: "R$",
        nextBillingDate: "Next billing date 14 June 2024", // aqui pode ser tbm "Your premium plan ends on 14 June 2024"
    },
    ...
}