import axios from "axios";
import { rotaDeProducao } from "./rotas";
import { CONSOLOGAR } from "@/components/Controle/debug";
// eslint-disable-next-line no-unused-vars
import EnderecoModel from "@/model/enderecoModel";
// eslint-disable-next-line no-unused-vars
import ClienteModel from "@/model/clienteModel";
import TiposPagamentoModel from "@/model/tiposPagamentoModel";
import MeusPedidosModel from "@/model/meusPedidosModel";

class ApiDelivery {

    /**
     * Busca o cliente na api pelo telefone e cnpj.
     * @param {number | string} cnpj 
     * @param {number | string} telefone 
     * @returns {Promise<ClienteModel>} 
     */
    static async buscarCliente(cnpj, telefone) {
        let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
        console.log("buscarCliente")
        console.log(cnpjConvertido)
        return new Promise((resolve, reject) => {
            axios.get(rotaDeProducao() + "/delivery/buscarcliente?telefone=" + telefone + "&cnpj=" + cnpjConvertido)
            .then((response) => {
                const cliente = new ClienteModel().fromJson(response.data)
                resolve(cliente)
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        });
    }

    /**
     * Adiciona um cliente
     * @param {string | number} cnpj 
     * @param {string | number} telefone 
     * @param {string} nome 
     * @returns {Promise<any>} 
     */
    static async adicionarCliente(cnpj, telefone, nome) {
        let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
        let body = {
            "nome": nome,
            "email": null,
            "senha": null,
            "telefone": telefone,
            "cpf": null,
            "cnpj": cnpjConvertido
        }
        console.log("adicionarCliente rota")
        console.log(body)
        return new Promise((resolve, reject) => {
            axios.post(rotaDeProducao() + "/delivery/salvarcliente", body)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        });
    }

    /**
     * Adiciona um endereco com o Id do cliente 
     * @param {number | string} clienteId 
     * @param {EnderecoModel} endereco 
     * @returns {Promise<any>}  
     */
    static async adicionarEndereco(clienteId, endereco) {
        return new Promise((resolve, reject) => {
            axios.post(rotaDeProducao() + "/delivery/adicionarendereco?cliente=" + clienteId, endereco)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        });
    }
    /**
     * Atualiza um endereco com o id do endereco
     * @param {number | string} enderecoId 
     * @param {EnderecoModel} enderecoModel 
     * @returns {Promise<any>}  
     */
    static async atualizarEndereco(enderecoId, enderecoModel) {
        console.log("endereco id");
        console.log(enderecoId);
        return new Promise((resolve, reject) => {
            axios.put(rotaDeProducao() + "/delivery/atualizarendereco?id=" + enderecoId, enderecoModel)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        });
    }

    /**
     * Busca o endereco por CEP na api viacep.
     * @param {string | number} cep 
     * @returns {Promise<EnderecoModel>}  
     */
    static async buscarEnderecoPorCEP(cep) {
        return new Promise(function (resolve, reject) {
            axios.get("https://viacep.com.br/ws/" + cep + "/json/")
                .then((res) => {
                    let endereco = new EnderecoModel().fromJson(res.data)
                    resolve(endereco)
                })
                .catch((err) => reject(err))
        })
    }

    /**
     * Busca todos os bairros cadastrados no allfood
     * @param {number | string} cnpj 
     * @param {number | string} numeroEmpresaFK 
     * @returns {Promise<EnderecoModel>}  
     */
    static async buscarBairrosAllfood(cnpj, numeroEmpresaFK) {
        return new Promise(function (resolve, reject) {
            axios.get(rotaDeProducao() + `/delivery/bairros?cnpj=${cnpj}&numeroEmpresaFK=${numeroEmpresaFK}`)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => reject(err))
        })
    }
     
    /**
     * Verifica se a casa esta aberta e funcionando o delivery no memnto em que esta rota for acionada
     * @param {number | string} cnpj 
     * @param {number | string} numeroEmpresaFK 
     * @returns {Promise<object>} {aberto: boolean, descricao: string }
     */
    static async verificarCasaEstaAbertaAgora(cnpj, numeroEmpresaFK) {
        return new Promise(function (resolve, reject) {
            axios.get(rotaDeProducao() + `/delivery/casaaberta?cnpj=${cnpj}&numeroEmpresaFK=${numeroEmpresaFK}`)
            .then((response) => {
                let resposta = {
                    aberto: response.data.restauranteAberto == "1" || response.data.restauranteAberto == 1 ? true : false,
                    descricao: response.data.descricaoDelivery
                }
                resolve(resposta);
            }).catch((err) => {
                console.error(err);
                reject(err);
            })
        })
    }
     
    /**
     * Busca todos os bairros mas retorna apenas a taxa e tempo do bairro do parametro.
     * Retorna "Not Found" caso nao encontrew o bairro pelo nome
     * @param {string | number} cnpj 
     * @param {string | number} numeroEmpresaFK 
     * @param {string} bairro 
     * @returns {Promise<any>}
     */
    static async buscarTaxaDeEntrega(cnpj, numeroEmpresaFK, bairro) {
        return new Promise(function (resolve, reject) {
            axios.get(rotaDeProducao() + `/delivery/taxasentrega?cnpj=${cnpj}&numeroEmpresaFK=${numeroEmpresaFK}&bairro=${bairro}`)
            .then((response) => {
                const bairroEncontrado = response.data;

                console.log(typeof(bairroEncontrado))
                console.log(bairroEncontrado)

                if (bairroEncontrado.Nome == null) {
                    reject("Not Found")
                } else {
                    resolve(bairroEncontrado);
                }
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

    /**
     * Traz todos os pedidos (historico) e tambem o pedido atual com o status
     * @param {String | number } cnpj 
     * @param {String | number } telefone 
     * @returns {Promise<Array<MeusPedidosModel>>} promise
     */
    static async buscarPedidos(cnpj, telefone) {
        return new Promise(function (resolve, reject) {
            axios.get(rotaDeProducao() + `/delivery/listarpedidos?cnpj=${cnpj}&telefone=${telefone}`)
            .then((response) => {
                console.log(response.data);
                let lista = []
                for (var i = 0; i < response.data.length; i++) {
                    lista.push(new MeusPedidosModel().fromJson(response.data[i]))
                }
                resolve(lista);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    

    /**
     * Busca os tipos de pagamento que a casa aceita
     * @param {string | number} cnpj 
     * @param {string | number} numeroEmpresaFK 
     * @returns {Promise<TiposPagamentoModel>}
     */
    static async buscarTiposDePagamento(cnpj, numeroEmpresaFK) {
        return new Promise(function (resolve, reject) {
            axios.get(rotaDeProducao() + `/delivery/formaspagamento?cnpj=${cnpj}&numeroEmpresaFK=${numeroEmpresaFK}`,)
            .then((response) => {
                let data = new TiposPagamentoModel().fromJson(response.data)
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    
    /**
     * Envia o pedido com o endereco
     * @param {object} pedido 
     * @returns {Promise<any>}
     */
    static async enviarPedido(pedido) {
        return new Promise(function (resolve, reject) {
            axios.post(rotaDeProducao() + "/delivery/fazerpedido", pedido)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
}

export default ApiDelivery

export function apiBuscarEnderecoPorCEP(cep) {
    return new Promise(function (resolve, reject) {
        axios.get("https://viacep.com.br/ws/" + cep + "/json/")
            .then((res) => resolve(res.data))
            .catch((err) => reject(err))
    })
}

export async function apiDeliveryBuscarFuncionamentoDaCasa(cnpj) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    let momento = Date.now();
    return new Promise(function (resolve, reject) {
        setTimeout(() => {
            console.log(cnpjConvertido)
            console.log(momento)
            console.log(reject)

            resolve(true)
        }, 2000);
    })
}

export async function apiEnviarTelefoneDelivery(cnpj, telefone) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    try {
        const data = await axios.get(rotaDeProducao() + "/delivery/cnpj?=" + cnpjConvertido + "$telefone=" + telefone);
        let json = data.data; 
        return json;
    } catch (e) {
        CONSOLOGAR(["deu erro"])
        CONSOLOGAR([e])
    }
}

/**
 * 
 * @param {string} cnpj 
 * @param {string} telefone 
 * @returns {Promise}
 */
export async function apiStatusPedidoDelivery(cnpj, telefone) {
    let cnpjConvertido = cnpj.replaceAll(",", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    const url = "http://"
    return new Promise((resolve, reject) => {
        axios.get(url, {params: {
            cnpj: cnpjConvertido,
            telefone: telefone
        }}).then((response) => {
            resolve(response.data)
        })
        .catch((err) => {
            reject(err)
        })
    })
}

