<template>
    <div id="app">
        <!-- <h1>Meu app</h1> -->
        <!-- <h1 style="color: yellow; background: purple">iteracoes = {{iteracoes}}</h1> -->
        <router-view />
    </div>
</template>

<script>
export default {
    data() {
        return {
    
        };
    },
    
    created() {
    
    },

    methods: {

    },
};
</script>

<style>
/* font da dalcatech  */
@import url("https://fonts.googleapis.com/css?family=Maven+Pro");
@import url("https://fonts.googleapis.com/css?family=Roboto");
/* no root estao as variaveis constantes do css */
:root {
    --cor1: rgb(100, 0, 0);
    --cor2: rgb(100, 100, 0);

    --valor-verde: rgb(7, 114, 39);
    --valor-vermelho: rgb(204, 10, 10);
    --valor-azul: rgb(31, 39, 150);
    --valor-amarelo:rgb(145, 160, 79);

    --primary: #061730;
    --primary_azul:#3F51B5;

    --DarkOrange:#FF8C00;
    --OrangeRed:#FF4500;

    --texto-na-cor-principal: white;
    --cor-principalTransparente: #c9050515;
    --texto-na-cor-principal-transparente: black;
    --cor-principalSemiTransparente: #c9050550;
    --texto-na-cor-principal-semi-transparente: black;

    --gradiente-verde: linear-gradient(to bottom, #258300, #175200);
    --gradiente-desabilitado: linear-gradient(to bottom, #c9c9c9, #818181);
    --cor-vermelho: rgb(200, 50, 20);
    --cinza-fundo-itens: rgb(30, 30, 30);


    --gradiente-claro: linear-gradient(
        to bottom,
        var(--cor1),
        var(--cor1-escura)
    );

    --gradiente-background: linear-gradient(
        to bottom,
        var(--cor1-escura),
        var(--cor1)
    );

    --gradiente-escuro: linear-gradient(
        to bottom,
        var(--cor1),
        var(--cor1-escura)
    );

    --gradiente-btn-conta: linear-gradient(
        to bottom,
        var(--cor1),
        var(--cor1-escura)
    );

    --gradiente-background: linear-gradient(
        to bottom,
        var(--cor1),
        var(--cor1)
    );

    --gradiente-contraste: linear-gradient(
        to bottom,
        var(--cor2),
        var(--cor2-escura)
    );

}

.clicable { cursor: pointer; }

.background-gradiente {
    width: 100%;
    height: 100%;
    /* min-height: 90vh; */
    background: var(--gradiente-background);

}
.container-nome-item {
    padding: 15px;
}

.card-titulo {
    padding-block: 15px;
    color: #000;
}

.router-link {
    margin: 0;
    padding: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    color: white;
    text-decoration: none;
}

.background {
    margin: 0;
}

.background-branco {
    background: white;
    border: var(--primaryDark_azul) solid 10px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
}

#app {
    font-family: Roboto, Maven Pro, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

/* .swal2-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
} */

.swal2-title {
    font-family: monospace !important;
    font-size: 20px !important;
    color: black;
}


.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 

.anim-entrada {
    animation: anim-entrada 0.5s forwards;
}
@keyframes anim-entrada {
    from {opacity: 0; transform: translateX(-10%);}
    to {opacity: 1; transform: translateX(0%);}
}


/* -------------------------------------------------------- titulos -------------------------------------------------------------------- */
body {
    margin: 0;
    background: black;
}

p {
    margin: 0px;
}

h1 {
    margin: 10px;
    color: white;
}
h2 {
    margin: 8px;
    color: white;
}
h3 {
    margin: 6px;
    color: white;
}
h4 {
    margin: 4px;
    color: white;
}
h5 {
    margin: 2px;
    color: white;
}
caption {
    margin: 0;
    font-size: 11px;
}

.texto-desabilitado {
    padding-block: 20px;
    padding-inline: 40px;
    color: gray;
}

.combo-sobre-o-item {
    margin-top: 25px;
    color: white
}

/* -------------------------------------------------------- forms -------------------------------------------------------------------- */
input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    font-size: 1.1rem;
}

.input-label { 
    margin-top: 25px;
    margin-bottom: 5px;
}

.input-select {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    font-size: 1.1rem; 
}

.form {
    margin-block: 15px; 
    margin-inline: 15px;
    border: white solid 2px;
    padding: 30px; 
    border-radius: 15px;
    background: linear-gradient(to top left, var(--cor1-muito-escura), black);
    /* max-width: 80%; */
}



/* -------------------------------------------------------- rows -------------------------------------------------------------------- */
.rowSpaceBetween {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

/* -------------------------------------------------------- nav -------------------------------------------------------------------- */
nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: var(--primaryDark_azul);
}

nav a.router-link-exact-active {
    color: var(--primaryDark_azul);
}

.perguntaEtapas {
    color: white;
    background: var(--primaryDark_azul);
    padding-top: 20px;
    margin: 0;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.btnEtapas {
    color: white;
    background: var(--primaryDark_azul);
    border-radius: 10px;
    border-color: transparent;
    padding: 10px 20px;
    font-size: 18px;
    margin-bottom: 10px;
}

/* ------------------------------------------------------------------------------------ swal sweetalert2 dialog alerts */
.swal2-popup, .swal2-title  {
    font-family: Arial, Helvetica, sans-serif !important;
}

.swal2-confirm {
    background: var(--gradiente-verde) !important;
    min-width: 120px !important;
}
.swal2-cancel {
    min-width: 120px !important;
}


/* ------------------------------------------------------------------------------------ buttons */
button {
    border: none;
    background: var(--gradiente-claro);
    color: white;
    font-size: 16px;
}

.btnProximo {
    animation: btnProximoAnim 0.5s forwards;
}

@keyframes btnProximoAnim {
    from {
        transform: scale(0.2);
    }
    to {
        transform: scale(1);
    }
}

.btn-normal {
    color: white;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    min-height: 45px;
}


.btnSelecionar {
    background: green;
}

.btnVoltar {
    background: red;
}

.btnTranparente {
    background: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 5px;
}

.btn-desabilitado {
    align-items: center;
    display: flex;
    justify-content: space-around;
    background: var(--gradiente-desabilitado);
    border-radius: 5px;
    padding-block: 5px;
    padding-inline: 10px;
    color: #666;
}
.btn-desabilitado > img {
    height: 25px;
    filter: invert(40%);
    margin-right: 5px;
}

.btn-verde {
    align-items: center;
    display: flex;
    justify-content: space-around;
    background: var(--gradiente-verde);
    border-radius: 5px;
    padding-block: 5px;
    padding-inline: 10px;
}
.btn-verde > img {
    height: 25px;
    filter: invert(100%);
    margin-right: 5px;
}

.btn-fechar-top {
    position: absolute;
    top: 0;
    right: 0;
    height: 45px;
    width: 45px;
    background: var(--cor-vermelho);
    font-size: 20px;
    font-weight: bolder;
    z-index: 15;
}

.buttonDisabled {
    border-radius: 5px;
    border-width: 0;
    box-shadow: 1px 1px 1px rgba(55, 55, 55, 0.3);
    background: rgb(107, 107, 107);
    color: rgb(202, 202, 202);
    font-size: 16px;
    padding: 8px 16px;
}
 
.form-btn {
    color: white;
    padding: 5px;
    width: 100%;
    border-radius: 5px;
}
/* ------------------------------------------------------------------------------------ BARS */
.bottomBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-items: center;
}

/* ------------------------------------------------------------------------------------ radioButton */
.radioButton {
    font-size: 32px;
    margin: 0;
}
/* ------------------------------------------------------------------------------------ cards */
.card {
    margin: 10px;
    background: white;
    border-radius: 20px;
    padding: 15px;
    color: black; 
}
.card-outline {
    margin: 10px;
    border: solid 1px black;
    border-radius: 20px;
    padding: 15px;
    color: black;
}
.card-outline-error {
    margin: 10px;
    border: solid 4px rgb(255, 0, 0);
    border-radius: 20px;
    padding: 15px;
    color: black;
}

.card .titulo {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 18px;
    padding-block: 10px;
    margin-bottom: 10px;
}

.card .titulo > p {
    margin-block: 0px;
    font-weight: bolder;
    font-size: 16px;
    color: white;
}

.card .opcao {
    /* background: red; */
    margin: 0;
    padding-inline: 10px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    color: #000;
}
.card .opcao p {
    color: #000;
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-left: 10px;
}

.card .opcao div {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.card .opcao img {
    color: #000;
    filter: invert(100%);
    height: 25px;
    width: 25px;
    margin: 0;
    padding: 0;
}

.card-pagina {color: black; border-radius: 10px; padding: 10px; background: white; margin: 15px; border:var(--cor1) 10px solid}

.card-pagina hr {
    margin-block: 15px;
    border-top: 1px solid black;
}

.card-pagina .titulo {color: var(--cor1); font-size: 20px; font-weight: bold; margin-block: 10px;}

/* ------------------------------------------------------------------------------------ checkbox */

.check-box-selecionado {
}
.check-box-nao-selecionado {
}

.debug {
    position: relative;
    width: 90%;
    margin-inline: auto;
    background: red;
    color: white;
    padding: 15px;
    word-wrap: break-word;
}

.checkboxLabel {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkboxLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkboxSpan {
    /* Create a custom checkbox */
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}
/* When the checkbox is diabled */
.checkboxLabel input[type="checkbox"]:disabled ~ .checkboxSpan {
    background-color: #7a8791;
}
/* When the checkbox is checked, add a blue background */
.checkboxLabel input:checked ~ .checkboxSpan {
    background-color: #2196f3;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkboxSpan:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.checkboxLabel input:checked ~ .checkboxSpan:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkboxLabel .checkboxSpan:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/* ------------------------------------------------------------------------------------ animacoes globais */
.animar-opcao {
    animation: keyframes-animar-opcao 1s forwards;
}
@keyframes keyframes-animar-opcao {
    from {scale: 1.03; transform: translateX(1%); color: green};
    to {scale: 1.0; transform: translateX(0%); color: black};
}
.animar-opcao img {
    animation: keyframes-animar-opcao-img 0.3s forwards;
}
@keyframes keyframes-animar-opcao-img {
    from {opacity: 0; transform: scaleX(50%)};
    to {opacity: 1; transform: scaleX(100%)};
}
</style>
