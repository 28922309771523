<template>
    <div class="anim-entrada">

        <div class="form" v-if="!loading" style="margin-inline: -10px;">

            <div class="input-btn">
                <p style="margin-bottom: 5px;">CEP</p>
                <div class="" >
                    <input type="number" id="cp" v-model="cep" @keyup.enter="enviarCep">
                    <button class="btn-normal" v-if="!loading" @click="enviarCep">Buscar CEP</button>
                    <img v-if="loading" src="../../assets/loading.gif" alt="" style="height: 35px" >
                </div>
            </div>

            <div v-if="this.cepNaoEncontrado">
                <p style="margin-top: 25px;">O CEP digitado não foi encontrado. Por favor, entre com seu endereço completo nos campos abaixo.</p>
            </div>

            <!-- <div > -->
            <div v-if="buscouCep">

                <div v-if="exigirNome">
                    <p class="input-label">Seu nome</p>
                    <input type="text" id="nome" v-model="nome" >
                </div>

                <p class="input-label">Rua</p>
                <input type="text" id="logradouro" v-model="rua" >

                <p class="input-label">Número</p>
                <input type="number" id="numero" v-model="numero">

                <p class="input-label">Complemento</p>
                <input type="text" id="complemento" v-model="complemento" placeholder="Caso necessário">


                <!-- <div>
                    <p class="input-label">Bairro</p>
                    <input type="text" placeholder="bairro" list="bairros-list" v-model="bairroSelecionado">
                    <datalist id="bairros-list"  >
                        <option v-for="(b, key) in bairrosAllFood" :key="key" :value="b.Nome"   @click="(v) => {this.escolheuBairro(b.Nome)}">{{ b.Nome }}</option>
                    </datalist>
                </div> -->

                <p class="input-label">Bairro</p>
                <bairros-autocomplete :listaCompleta="bairrosAllFood" @onSelect="this.escolheuBairro"/>

                <p class="input-label">Cidade</p>
                <input type="text" id="cidade" v-model="cidade" >

                <p class="input-label">Estado</p>
                <input type="text" id="uf" v-model="uf" >

                <button v-if="!validou" class="btn-normal" style="margin-top: 35px; background: grey" @click="salvar">Enviar</button>
                <button v-if="validou" class="btn-normal" style="margin-top: 35px;" @click="salvar">Enviar</button>
            </div>
            
        </div> 

        <div v-if="loading" class="form-outline center" style="height: 300px; max-width: 100%;">
            <img src="../../assets/loading.gif" alt="" style="height: 50px" />
        </div>











        <!-- <p class="input-label">Bairro</p>
        <bairros-autocomplete :listaCompleta="bairrosAllFood" @onSelect="this.escolheuBairro"/> -->
    </div>
</template>

<script>
import ApiDelivery from '@/api/ApisDelivery'
// import { CONSOLOGAR } from '../Controle/debug'
import EnderecoModel from '@/model/enderecoModel'
import Swal from 'sweetalert2'
import BairrosAutocomplete from './BairrosAutocomplete.vue'
export default {
    components: { BairrosAutocomplete },

    name: "DadosEndereco",

    props: {
        exigirNome: Boolean,
        idCliente: Number,
        telefoneCliente: String,
        usarEnderecoAntigo: Boolean
    },

    data() {
        return  {
            loading: false,
            buscouCep: false,
            cepNaoEncontrado: false,
            nome: "",
            rua: "",
            numero: "",
            cep: "", 
            bairro: "",
            complemento: "",
            cidade: "",
            uf: "",
            //
            enderecoAnterior: this.$store.state.deliveryEndereco,
            bairrosAllFood: this.$store.state.lista_bairros,
        }
    },

    created() {
        this.setarDadosEnderecoAntigo()
    },

    watch: {
        usarEnderecoAntigo() {
            console.log("comecamos de novo aqui ")
            this.setarDadosEnderecoAntigo()
        },
    },

    computed: {
        validou() {
            if (this.nome == "" && this.exigirNome) {
                return false
            }
            if (this.rua == "") {
                return false
            }
            if (this.numero == "") {
                return false
            }
            if (this.cep == "") {
                return false
            }
            if (this.bairro == "") {
                return false
            }
            if (this.cidade == "") {
                return false
            }
            if (this.uf == "") {
                return false
            }
            return true   
        }
    },
    methods: {
        setarDadosEnderecoAntigo() {
            console.log("comecei de novo")
            if (this.usarEnderecoAntigo) {
                let enderecoAntigo = this.$store.state.deliveryEndereco
                this.rua = enderecoAntigo.rua
                this.numero = enderecoAntigo.numero
                this.cep = enderecoAntigo.cep
                this.bairro = enderecoAntigo.bairro
                this.complemento = enderecoAntigo.complemento ?? ""
                this.cidade = enderecoAntigo.cidade
                this.uf = enderecoAntigo.uf
                this.buscouCep = true
            } else {
                this.rua = null
                this.numero = null
                this.cep = null
                this.bairro = null
                this.complemento = null
                this.cidade = null
                this.uf = null
                this.buscouCep = false
            }
        },

        enviarCep() {
            this.loading = true;
            ApiDelivery.buscarEnderecoPorCEP(this.cep)
                .then(endereco => {
                    this.rua = endereco.rua;
                    // this.bairro = endereco.bairro;
                    this.cidade = endereco.cidade;
                    this.uf = endereco.uf;
                    this.loading = false;
                    this.buscouCep = true;
                })
                // eslint-disable-next-line no-unused-vars
                .catch(err => {
                    Swal.fire({title: "CEP não encontrado"})
                    this.loading = false;
                    this.buscouCep = true; 
                })
        },

        escolheuBairro(val) {
            console.log(`%c ${val}`, 'background: blue')
            this.bairro = val
        },


        gerarObjetoEndereco() {
            let dados = new EnderecoModel()
            dados.bairro = this.bairro
            dados.cep = this.cep
            dados.cidade = this.cidade
            dados.complemento = this.complemento ?? ""
            dados.rua = this.rua
            dados.numero = this.numero
            dados.uf = this.uf
            dados.enderecoPrincipal = true
            return dados
        },

        validar() {
            if (this.nome == "" && this.exigirNome) {
                Swal.fire({title: "Por favor, preencha seu NOME"})
                return false
            }
            if (this.rua == "") {
                Swal.fire({title: "Por favor, preencha o nome da RUA"})
                return false
            }
            if (this.numero == "") {
                Swal.fire({title: "Por favor, preencha o NÚMERO do seu endereço"})
                return false
            }
            if (this.cep == "") {
                Swal.fire({title: "Por favor, preencha o CEP"})
                return false
            }
            if (this.bairro == "") {
                Swal.fire({title: "Por favor, preencha o BAIRRO"})
                return false
            }
            if (this.cidade == "") {
                Swal.fire({title: "Por favor, preencha a CIDADE"})
                return false
            }
            if (this.uf == "") {
                Swal.fire({title: "Por favor, preencha o ESTADO (UF)"})
                return false
            }
            return true   
        
        },

        async salvar() {
            if (this.validar()) {
                
                const endereco = this.gerarObjetoEndereco()
                this.loading = true

                /** se o andereco antigo for nulo, entao estamos adicionando um endereco e cliente */
                if (this.usarEnderecoAntigo == false) {
                    console.log("adicionando endereco");
                    let cliente = await ApiDelivery.adicionarCliente(this.$store.state.cnpj, this.telefoneCliente, this.nome)
                    await ApiDelivery.adicionarEndereco(cliente.Id, endereco)
                } 
                
                /** Se o endereco antigo nao for nulo, entao estamos atualizando ele */
                else {
                    console.log("ATUALIZANDO endereco");
                    endereco.id = this.$store.state.deliveryEndereco.id
                    await ApiDelivery.atualizarEndereco(this.$store.state.deliveryEndereco.id, endereco);
                    
                }
                
                /** setar no state o novo endereco */
                this.$store.dispatch('setDeliveryEndereco', endereco)
                
                this.$emit("onFinished")
            }


            this.loading = false
        },
    }
}
</script>

<style scoped>
.input-btn {
    width: 100%;
}
.input-btn  * {
    width: 100%;
    margin: 0;
    padding: 0;
}

</style>