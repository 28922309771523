<template>
    <div>

        <MenuTopTemplate :titulo="'Meus pedidos'" />
        <button class="btn-fechar-top" type="button" @click="voltar">X</button>

        
        <div class="corpo anim-entrada">    

<!-- digitar telefone -->
            <!-- <div class="status" v-if="todosPedidos == null"> -->
            <div class="status" >

                <div class="card " style="margin-inline: 10px;">
                    <TelefoneComDDD :mostrar="true" @callback="telefoneCallback" :whiteBackground="true" :loadingEnviarTelefone="loadingEnviarTelefone"/>
                </div>

            </div>


            <!-- <div class="card"  v-if="cada.status.toLowerCase() == 'concluido' || cada.status.toLowerCase() == 'cancelado' "></div> -->

<!-- status depois de digitado o telefone -->
            <div  v-if="todosPedidos != null">

                <h3>PEDIDOS CORRENTES</h3>


                <div class="card card-corrente" style="padding: 3px">
                    <div v-if="todosPedidos.length == 0" class="nenhum-pedido">
                        <p>Nenhum pedido corrente</p>
                    </div>

                    <div v-for="(cada, index) in todosPedidos" :key="index">
                        <div class="card"  v-if="cada.status.toLowerCase() !== 'concluido' && cada.status.toLowerCase() !== 'cancelado' ">
    <!-- data e status  -->
                            <div class="cada-pedido cada-pedido-borda">
                                <div class="flex-key-value">
                                    <!-- <p class="data-pedido">{{ cada.pedido.dataBrasil() }}</p> -->
                                    <p class="data-pedido">{{ cada.dataBrasil() }}</p>
                                    <!-- <p class="data-pedido">{{ cada.dataPedido}}</p> -->
                                    <p class="status-pedido">{{ cada.status }}</p>
                                </div>
                                
    <!-- itens  -->
                                <div class="items-container">
                                    <hr>
                                    <div style="margin-block: 8px;" v-for="(item, index2) in cada.pedido.itens" :key="index2">
                                        <p>{{ item.nome }}</p>
                                    </div>
                                    <hr>
                                </div>
    <!-- valores                              -->
                                <div class="flex-key-value">
                                    <p>Valor do pedido</p>
                                    <p>{{ !cada.pedido.valorTotalCarrinho ? "" : converterParaVirgula(cada.pedido.valorTotalCarrinho) }}</p>
                                    
                                </div>
                                <div class="flex-key-value" v-if="cada.pedido.taxaEntrega">
                                    <p>Taxa de entrega</p>
                                    <p>{{ !cada.pedido.taxaEntrega ? "" : converterParaVirgula(cada.pedido.taxaEntrega) }}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <h3>PEDIDOS ANTERIORES</h3>

                <div class="card"  style="padding: 3px">
                    <div v-if="todosPedidos.length == 0" class="nenhum-pedido">
                        <p>Nenhum pedido anterior</p>
                    </div>

                    <div   v-for="(cada, index) in todosPedidos" :key="index">
                        <div  v-if="cada.status.toLowerCase() == 'concluido' || cada.status.toLowerCase() == 'cancelado' ">

                            <!-- data e status  -->
                            <div class="cada-pedido cada-pedido-anterior">

                                <div class="flex-key-value">
                                    <p class="data-pedido">{{ cada.dataBrasil() }}</p>
                                    <p class="status-pedido">{{ cada.status }}</p>
                                </div>

                                <!-- itens  -->
                                <div style="margin-block: 15px;" v-for="(item, index2) in cada.pedido.itens" :key="index2">
                                    <p>{{ item.nome }}</p>
                                </div>

                                <!-- valores                              -->
                                <div class="flex-key-value">
                                    <p>Valor do pedido</p>
                                    <p>{{ !cada.pedido.valorTotalCarrinho ? "" : converterParaVirgula(cada.pedido.valorTotalCarrinho) }}</p>
                                </div>

                                <hr>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        

  
        
        <div style="width: 100%;">
            <!-- <menu-bottom-template  /> -->
            <button style="width: 100%;height: 50px;" @click="onClickVoltar">VOLTAR</button>
        </div>

    </div>  

</template>

<script>
import MenuTopTemplate from "../components/Base/MenuTopTemplate.vue"
// import MenuBottomTemplate from '@/components/Base/MenuBottomTemplate.vue'
import TelefoneComDDD from '@/components/Delivery/TelefoneComDDD.vue'
import Swal from 'sweetalert2'
import ApiDelivery from '@/api/ApisDelivery'
import { converterParaDinheiroString } from '@/utils/Dinheiro'
import { setarCores } from '@/utils/SetarCores'

export default {
    name: "StatusDeliveryView",

    components: { MenuTopTemplate,  TelefoneComDDD },

    data() {
        return {
            loading: false,
            loadingEnviarTelefone: false,
            digitouDados: false,
            dadosConta: {},
            ddd: "",
            telefone: "",

            // daqui pra baixo eh o retorno da api
            todosPedidos: null,
            deveDarRefresh: true,
        }
    },

    created() {
        setarCores(this.$store.state.cor1, this.$store.state.cor2),
        this.setarPodeEnviar();
    },


    beforeDestroy() {
        this.deveDarRefresh = false
    },



    methods: {

        refreshar() {
            setTimeout(() => {
                if (this.deveDarRefresh) {
                    console.log("refreshar")
                    this.buscarPedidosSemLoading();
                    this.refreshar()
                }
            }, 30000);
        },

        telefoneCallback(tel) {
            this.telefone = tel.telefone;
            console.log(tel.telefone);
            this.enviarTelefone()
        },

        voltar() {
            this.$router.back();
        },

        converterParaVirgula(dindin) {
            return converterParaDinheiroString(dindin)
        },

        enviarTelefone() {
            this.loadingEnviarTelefone = true
            ApiDelivery.buscarPedidos(this.$store.state.cnpj, this.telefone)
                .then( res => {
                    console.log(res);
                    this.todosPedidos = res
                    this.loadingEnviarTelefone = false
                    this.refreshar()
                }).catch( err => {        
                    this.loadingEnviarTelefone = false
                    console.log(err)
                    Swal.fire({
                        title: "Nenhum pedido encontrado!",
                        html: "Parece que você ainda não fez nenhum pedido! <br><br> Após seu primeiro pedido, ele e os demais pedidos aparecerão aqui."
                    })
                })
        },

        buscarPedidosSemLoading() {
            ApiDelivery.buscarPedidos(this.$store.state.cnpj, this.telefone)
                .then( res => {
                    console.log(res);
                    this.todosPedidos = res
                    this.refreshar()
                }).catch( err => {
                    console.log(err)
                })
        },

        onClickVoltar() {
            window.history.back();
        }
    }
}
</script>1

<style scoped>

h3 {margin-top: 25px;}
.cada-pedido {
}

.nenhum-pedido {
    padding-inline: 35px;
    border-radius: 18px;
    background: linear-gradient(to bottom, white, grey);
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: rgb(117, 117, 117);
    font-weight: bold;
    font-style: italic;
}

.items-container {margin-block: 15px;}
.items-container > span {font-size: 0.7rem; border-bottom: 1px solid black;}

.cada-pedido-anterior {
    padding: 20px;
}
.cada-pedido-anterior hr {
    margin: 0;
    border: solid 2px black;
}

.card-corrente {
    background: linear-gradient(to bottom, var(--cor1), white);
    z-index: 10;
}
.data-pedido {font-size: 0.8rem; text-align: start;}
.status-pedido {font-size: 1.0rem; text-align: end; font-weight: bold;}

.flex-key-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-block: 2px;
}


</style>