<template>
    <div style="position: relative; width:auto;">

        <input type="text"  ref="mydivref">

        <div  class="autocomplete-bairros" v-if="mostrar">
            <div v-for="(b, index) in this.filtrado" :key="index">
                <p @click="() => onSelect(b.Nome)">{{ b.Nome }}</p>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "BairrosAutocomplete",
    props: {
        listaCompleta: Array,
    },
    data() {
        return {
            typed: "",
            bairro: null,
            mostrar: false,
            filtrado: [],
        };
    },
    watch:{
        typed(v) {
            this.onType(v);
            if (v != null && v.length > 0) {
                this.mostrar = true
            } else {
                this.mostrar = false
            }
        },
    },
    created() {
        this.criar()
    },
    methods: {
        criar() {
            setTimeout(() => {
                this.$refs.mydivref.addEventListener("input", (e) => {
                    let val = e.target.value
                    if (val.length > 0) {
                        this.mostrar = true
                        this.onType(val)
                    } else {
                        this.mostrar = false
                    }
                })
            }, 500);
        },
        onClick() {
            this.mostrar = !this.mostrar;
        },
        onType(val) {
            if (val == null || val.length == 0) {
                this.filtrado = []
            } else {
                this.filtrado = this.listaCompleta.filter((f) => f.Nome != null && f.Nome.toLowerCase().includes(val.toLowerCase()))
            }
        },
        onSelect(selected) {
            this.bairro = selected
            this.typed = selected
            this.$refs.mydivref.value = selected
            setTimeout(() => {
                this.mostrar = false
            }, 100);
            this.$emit('onSelect', selected)
        },
    },
}
</script>

<style scoped>
.autocomplete-bairros {
    position: absolute;
    background: white;
    color: black;
    padding: 10px;
    right: 0px;
    left: 0;
    z-index: 50;
    /* height: 100px; */
}

.autocomplete-bairros p {
    padding-block: 5px;
}

</style>