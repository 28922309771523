<template>

        <div>
            <div v-if="pix_payment_id != null">
                <mp-status-screen-pix :paymentId="pix_payment_id" :pix_data="pix_data" @onApproved="onPixApproved"/>
            </div>
            <div id="paymentBrick_container" v-if="pix_payment_id == null">
                <!-- brick do mercado pago aqui  -->
            </div>
        </div>
</template>

<script>
import { apiMercadoPagoCriarPagamento } from '@/api/ApiMercadoPago'
import Swal from 'sweetalert2'
import MpStatusScreenPix from './MpStatusScreenPix.vue'
export default {
    components: { MpStatusScreenPix },
    name: "MpPagamento",
    created() {
        this.mp()
    },
    
    mounted( ) {
        
    },
    
    props: {
        total: Number,
    },
    data() {
        return {
             public_key: this.$store.state.mp_public_key,
             pix_payment_id : null,
             pix_data : null,

             /** aqui vamos mandar pra api se foi PIX, DEBITO ou CREDITO */
             payment_method_id: null,
        }
    },
    methods: {
        printar() {
            console.log(window)
        },

        /**
         * Callback do [mp-status-screen-pix] para saber quando o pix foi pago e aprovado.
         */
        onPixApproved() {
            this.pagamentoFeitoComSucesso();
        },

        /**
         * Volta para o parent com o pagamento feito, pronto para enviar o pedido
         */
        pagamentoFeitoComSucesso() {
            this.$emit("onSubmit", this.payment_method_id)
        },

        /**
         */

        /**
         * Funcao chamada quando o botao "PAGAR" no bricks do mercado pago for pressionado
         * @param {*} formData Dados do retorno do pagamento apos ser enviado para a api pelo bricks do mercado pago
         */
        onSubmitMercadoPago(payment_method, formData) {
            if (formData.payment_type_id == null) {
                formData.payment_type_id = payment_method
            }
            apiMercadoPagoCriarPagamento(this.$store.state.cnpj, this.$store.state.numeroEmpresaFK, formData)
                .then(res => {
                    console.log("pagamento criado abaixo")
                    console.log(res)
                    this.payment_method_id = payment_method // para mandar pra api o medoto (pix, debit, credit)
                    if (formData.payment_method_id == "pix") {
                        this.pix_data = res.point_of_interaction.transaction_data.qr_code
                        this.pix_payment_id = res.id
                    } else {
                        if (res.status == "approved") {
                            this.pagamentoFeitoComSucesso()
                        } else {
                            Swal.fire({
                                text: "Pagamento não aprovado",
                                title: "Erro no pagamento"
                            })  
                        }
                    } 
                })
                .catch(err => {
                    Swal.fire({
                        title: "Erro",
                        text: err,
                    })
                })
        },

        /**
         * funcao que vai renderizar o brick do mercado pago
         */
        mp() {
            let amount = this.total;
            let public_key = this.public_key;
            
            const mp = new window.MercadoPago(public_key);

            const bricksBuilder = mp.bricks();

            const renderPaymentBrick = async (bricksBuilder) => {
                const settings = {

                    initialization: {
                        /*
                            "amount" é o valor total a ser pago por todos os meios de pagamento
                            com exceção da Conta Mercado Pago e Parcelamento sem cartão de crédito, que tem seu valor de processamento determinado no backend através do "preferenceId"
                        */
                        amount: amount,
                        preferenceId: "<PREFERENCE_ID>",
                    },

                    customization: {

                        visual: {
                            style: {
                                customVariables: {
                                    
                                },
                            },
                        },

                        paymentMethods: {
                            // ticket: "all", // boleto bancario
                            bankTransfer: "pix",
                            creditCard: "all",
                            // debitCard: "all",
                            minInstallments: 1,
                            maxInstallments: 1,
                            // mercadoPago: "all",
                        },

                    },

                    callbacks: {
                        onReady: () => {
                        /*
                            Callback chamado quando o Brick estiver pronto.
                            Aqui você pode ocultar loadings do seu site, por exemplo. 
                        */
                        },
                        onSubmit: ({ selectedPaymentMethod, formData }) => {
                            console.log(selectedPaymentMethod)
                            this.onSubmitMercadoPago(selectedPaymentMethod,formData)
                        },
                        
                        onError: (error) => {
                            // callback chamado para todos os casos de erro do Brick
                            console.error(error);
                        },
                    },
                };
                try {
                    window.paymentBrickController = await bricksBuilder.create(
                        "payment",
                        "paymentBrick_container",
                        settings
                    );
                } catch (error) {
                    alert(error);
                }
            };

            renderPaymentBrick(bricksBuilder);
        }
    }
}
</script>

<style scoped>

svg {
}

#paymentBrick_container {
    margin-inline: 15px;
}

</style>